import { Injectable } from '@angular/core';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { TitleColumn, EmployeeCountColumn, CreatedAtColumn } from '@company/services/tables/positions-table/columns';
import { Paginator } from '@app/classes';
import { Job } from '@models/company/job.model';

@Injectable()
export class PositionsIndexTable extends Table {
    links = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getLink(position: Job): string[] {
        return ['/company', 'positions', position.id.toString()];
    }

    setColumns(): void {
        this.addColumns(new TitleColumn(), new EmployeeCountColumn(), new CreatedAtColumn());
    }

    setDataSource(): void {
        this.paginator = new Paginator(Job.param('company', this.auth.company.id));
    }
}
