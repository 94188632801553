import { Injectable } from '@angular/core';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import {
    AddressColumn,
    CityColumn,
    CountryColumn,
    NameColumn,
    PostalCodeColumn,
    ProvinceColumn,
} from '@company/services/tables/offices-table/columns';
import { Paginator } from '@app/classes';
import { Office } from '@models/company/office.model';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { Company } from '@models/company/company.model';

const makePrimaryOption: MenuColumnItem = {
    label: 'company.offices.makePrimaryLabel',
    event: 'makePrimary',
};

@Injectable()
export class OfficesIndexTable extends Table {
    links = true;
    showMenuColumnOptions = true;
    addressColumn: AddressColumn;
    company: Company;

    constructor(protected auth: AuthService) {
        super(auth);
        this.addressColumn = new AddressColumn();
        this.boot();
    }

    getMenuColumnOptionsForRow(office: Office): MenuColumnItem[] {
        return this.auth.can(Office.permission.edit) && this.company?.primaryAddressId !== office.id
            ? [makePrimaryOption]
            : [];
    }

    getLink(office: Office): (string | number)[] {
        return ['/company', 'offices', office.id];
    }

    setCompany(company: Company): void {
        this.company = company;
        this.addressColumn.setPrimaryAddressId(this.company.primaryAddressId);
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            this.addressColumn,
            new CityColumn(),
            new ProvinceColumn(),
            new PostalCodeColumn(),
            new CountryColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Office.param('company', this.auth.company.id));
    }
}
